import { BotIcon, ThumbsDownIcon, ThumbsUpIcon } from 'lucide-react';
import { useSupportBot } from '../hooks/useSupportBot';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import CloseIcon from '@c/modals/assets/CloseIcon';

export const SupportBotButton = ({
  chat_id,
  onNextMessage,
}: {
  chat_id: string;
  onNextMessage: (message: string) => void;
}) => {
  const { getNextMessageMutation, submitFeedbackMutation, state } =
    useSupportBot({
      onNextMessage,
    });
  const { userDoc } = useAuth();
  if (!userDoc?.roles?.admin) return null;
  if (state === 'ready') {
    return (
      <button
        type="button"
        className="flex aspect-square h-[4rem] flex-col items-center justify-center rounded-full border-2 bg-brand-secondary text-white transition-colors"
        onClick={() => {
          getNextMessageMutation.mutate(chat_id);
        }}
      >
        {getNextMessageMutation.isPending ? (
          // do 3 dots
          <div className="flex h-[2rem] w-[2rem] items-center justify-center gap-5">
            <div className="h-[2rem] w-[2rem] animate-spin rounded-full border-2 border-t-transparent" />
          </div>
        ) : (
          <BotIcon />
        )}
      </button>
    );
  }
  // Else return two buttons, thumbs up or thumbs down
  return (
    <div className="flex gap-2">
      <button
        type="button"
        title="Not helpful"
        className="flex aspect-square h-[4rem] flex-col items-center justify-center rounded-full border-2 bg-brand-secondary text-white transition-colors"
        onClick={() => {
          submitFeedbackMutation.mutate({
            feedback: 'not helpful',
          });
        }}
      >
        {submitFeedbackMutation.isPending ? (
          <div className="flex h-[2rem] w-[2rem] items-center justify-center gap-5">
            <div className="h-[2rem] w-[2rem] animate-spin rounded-full border-2 border-t-transparent" />
          </div>
        ) : (
          <ThumbsDownIcon />
        )}
      </button>
      <button
        type="button"
        title="Helpful"
        className="flex aspect-square h-[4rem] flex-col items-center justify-center rounded-full border-2 bg-green-500 text-white transition-colors"
        onClick={() => {
          submitFeedbackMutation.mutate({
            feedback: 'helpful',
          });
        }}
      >
        {submitFeedbackMutation.isPending ? (
          <div className="flex h-[2rem] w-[2rem] items-center justify-center gap-5">
            <div className="h-[2rem] w-[2rem] animate-spin rounded-full border-2 border-t-transparent" />
          </div>
        ) : (
          <ThumbsUpIcon />
        )}
      </button>
      <button
        type="button"
        title="Helpful"
        className="flex aspect-square h-[4rem] flex-col items-center justify-center rounded-full border-2 bg-green-500 text-white transition-colors"
        onClick={() => {
          submitFeedbackMutation.mutate({
            feedback: '',
          });
        }}
      >
        <CloseIcon />
      </button>
    </div>
  );
};
