import ProductCouponDisplay from '@c/ProductCouponDisplay';
import { PublicUserDocument } from '@models/user';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import {
  addProductToFavorites,
  hasValidVariations,
  removeProductFromFavorites,
} from '@util/firestore/products';
import { getPublicUserDoc } from '@util/firestore/users';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getCartItemFromProductDocument, isMobile } from '@util/index';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useToastContext } from 'context/ToastContext';
import dayjs from 'dayjs';
import MakeAnOfferButton from 'features/makeAnOffer/components/MakeAnOfferButton';
import { HeartIcon } from 'lucide-react';
import { ProductDocument, Variation } from 'models/product';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

type Props = {
  product: ProductDocument;
  seller?: PublicUserDocument | null;
  selectedVariations: Variation[] | null;
  onAddToCartError: () => void;
  hideOffer?: boolean;
  customInputs?: {
    enabled: boolean;
    values: Record<string, string>;
    cost: number;
  };
};

export function hasValidCustomInput(
  product: ProductDocument,
  customInputs: {
    enabled: boolean;
    values: Record<string, string>;
    cost: number;
  }
) {
  if (!product.custom_inputs || !customInputs.enabled) return true;

  // Check that all required fields have values
  return product.custom_inputs.fields.every((field) => {
    if (!field.required) return true;
    return !!customInputs.values[field.id];
  });
}

export const BuyAddOfferButtons = ({
  product,
  selectedVariations,
  onAddToCartError,
  hideOffer,
  customInputs,
}: Props) => {
  const { data: seller } = useQuery({
    queryKey: ['publicUser', product.seller_id],
    queryFn: () => getPublicUserDoc({ uid: product.seller_id }),
    enabled: !!product.seller_id,
  });
  const { userDoc, user, signInAnon } = useAuth();
  const { increaseCartQty, canAddProductToCart, cart, setCartOpen } =
    useShoppingCart();
  const [liked, setLiked] = React.useState(false);
  const { getAttribution } = useAttributionContext();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { showErrorToast, showSuccessToast } = useToastContext();
  const [nextAction, setNextAction] = useState<'' | 'buyNow' | 'addToCart'>('');

  React.useEffect(() => {
    if (userDoc) {
      const isFavorite = userDoc.favorites.includes(product.id);
      setLiked(isFavorite);
    }
  }, [userDoc, product.id]);

  const [loadingAddToCart, setLoadingAddToCart] = useState(false);
  const handleAddToCart = async () => {
    if (user) {
      if (!hasValidVariations(product, selectedVariations)) {
        showErrorToast('Invalid variations');

        onAddToCartError();
        return;
      }

      if (
        customInputs?.enabled &&
        !hasValidCustomInput(product, customInputs)
      ) {
        showErrorToast('Please fill out all required fields');
        return;
      }

      if (!canAddProductToCart(product.id) && showBuyButton) {
        showErrorToast('Cannot add product to cart');
        return;
      }

      const attribution = getAttribution(product.id);

      const cartItem = getCartItemFromProductDocument(
        product,
        selectedVariations,
        attribution,
        customInputs
      );

      increaseCartQty(cartItem);
      showSuccessToast('Added to cart');
      if (isMobile()) setCartOpen(true);
    } else {
      try {
        setLoadingAddToCart(true);
        await signInAnon();
        setNextAction('addToCart');
      } catch (e) {
        setLoadingAddToCart(false);
        showErrorToast((e as Error).message);
      } finally {
        return;
      }
    }
    if (!hasValidVariations(product, selectedVariations)) {
      showErrorToast('Invalid variations');

      onAddToCartError();
      return;
    }

    if (customInputs?.enabled && !hasValidCustomInput(product, customInputs)) {
      showErrorToast('Please fill out all required fields');
      return;
    }

    if (!canAddProductToCart(product.id) && showBuyButton) {
      showErrorToast('Cannot add product to cart');

      setCartOpen(true);
      return;
    }

    const attribution = getAttribution(product.id);

    const cartItem = getCartItemFromProductDocument(
      product,
      selectedVariations,
      attribution,
      customInputs
    );

    increaseCartQty(cartItem);
  };

  const [loadingBuyNow, setLoadingBuyNow] = useState(false);
  const handleBuyNow = async () => {
    if (user) {
      if (
        hasValidVariations(product, selectedVariations) &&
        canAddProductToCart(product.id) &&
        showBuyButton
      ) {
        if (
          customInputs?.enabled &&
          !hasValidCustomInput(product, customInputs)
        ) {
          showErrorToast('Please fill out all required fields');
          return;
        }
        const cartItem = getCartItemFromProductDocument(
          product,
          selectedVariations,
          undefined,
          customInputs
        );
        increaseCartQty(cartItem);
        router.push('/checkout');
      } else {
        onAddToCartError();
      }
    } else {
      try {
        setLoadingBuyNow(true);
        await signInAnon();
        setNextAction('buyNow');
      } catch (e) {
        setLoadingBuyNow(false);
        showErrorToast((e as Error).message);
      } finally {
        return;
      }
    }
    if (!hasValidVariations(product, selectedVariations)) {
      showErrorToast('Invalid variations');
      onAddToCartError();
      return;
    }
    if (!canAddProductToCart(product.id) && showBuyButton) {
      showErrorToast('Cannot add product to cart');
      onAddToCartError();
      setCartOpen(true);
      return;
    }
    if (customInputs?.enabled && !hasValidCustomInput(product, customInputs)) {
      showErrorToast('Please fill out all required fields');
      onAddToCartError();
      return;
    }
    const cartItem = getCartItemFromProductDocument(
      product,
      selectedVariations,
      undefined,
      customInputs
    );
    increaseCartQty(cartItem);
    router.push('/checkout');
  };

  const handleLikeClick = async () => {
    if (!userDoc) {
      router.push('/login');
      return;
    }
    const isFavorite = userDoc.favorites.includes(product.id);
    if (isFavorite) {
      await removeProductFromFavorites(userDoc.uid ?? '', product.id);
    } else {
      await addProductToFavorites(userDoc.uid ?? '', product.id);
    }

    setLiked(!liked);

    queryClient.invalidateQueries({
      queryKey: ['authUser'],
    });
  };

  const { status } = useOnlineStatus(seller?.uid);
  const today = dayjs();
  const lastChangeDate = dayjs(status?.last_changed);
  const lastSeenInWeeks = today.diff(lastChangeDate, 'week');

  const showBuyButton =
    (!userDoc && !seller?.on_vacation) ||
    (status && lastSeenInWeeks < 2 && !seller?.on_vacation);

  useEffect(() => {
    if (nextAction && user && userDoc && cart && status) {
      if (nextAction === 'buyNow') {
        handleBuyNow();
      } else if (nextAction === 'addToCart') {
        handleAddToCart();
        setLoadingAddToCart(false);
      }
      setNextAction('');
    }
  }, [nextAction, user, userDoc, cart, status]);
  return (
    <>
      <div className="flex flex-col gap-[1rem]">
        <div className="block w-full sm:hidden">
          <ProductCouponDisplay product={product} />
        </div>

        {showBuyButton && (
          <Button
            type="secondary"
            text="Buy It Now"
            width="fluid"
            loading={loadingBuyNow}
            disabled={product.out_of_stock}
            onClick={handleBuyNow}
            className="!text-[1.8rem]"
          />
        )}

        {showBuyButton && (
          <Button
            text="Add to Cart"
            width="fluid"
            type="outline"
            loading={loadingAddToCart}
            disabled={product.out_of_stock}
            onClick={handleAddToCart}
            className="!text-[1.8rem]"
          />
        )}

        {hideOffer ? (
          <div className="hidden sm:flex">
            <Button
              type="outline"
              width="fluid"
              text={liked ? 'In Favorites' : 'Add to Favorites'}
              leadingIcon={
                <HeartIcon
                  className={`${
                    liked ? 'fill-white' : '!text-brand-secondary'
                  } h-10 w-10`}
                />
              }
              className={`${
                liked
                  ? '!border-none !bg-brand-secondary text-white'
                  : 'border-brand-secondary text-brand-secondary'
              }  text-[1.8rem]`}
              disabled={product.out_of_stock}
              onClick={handleLikeClick}
            />
          </div>
        ) : (
          <MakeAnOfferButton
            product={product}
            selectedVariations={selectedVariations}
          />
        )}
      </div>
    </>
  );
};

export default BuyAddOfferButtons;
