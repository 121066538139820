import { useQuery } from '@tanstack/react-query';
import SafeImage from '@ui/SafeImage';
import { getProductById } from '@util/firestore/products';
import { useOffers } from './useOffers';
import { formatCurrency } from '@util/index';
import Link from 'next/link';
import { getProductSlug } from '@util/urlHelpers';
import { ArrowUpRightIcon } from 'lucide-react';

export default function MobileMessageOffer({ offer_id }: { offer_id: string }) {
  const { offer } = useOffers(offer_id);

  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: () => getProductById(offer!.product_id),
    enabled: !!offer?.product_id,
  });

  if (!product) return null;

  const sizes = [offer?.variation?.letter, offer?.variation?.number]
    .filter(Boolean)
    .join('/');

  return (
    <div className="flex flex-col">
      <Link
        href={getProductSlug(product)}
        className="flex h-full w-full justify-between gap-[1.2rem] bg-brand-lightest-gray px-[1.6rem] py-[1.2rem]"
      >
        <div className="flex items-center gap-x-6">
          <div className="relative h-[5.5rem] w-[5.5rem]">
            <SafeImage
              src={product.thumbnail}
              width={200}
              height={200}
              alt="product image"
              className="aspect-square h-full w-full flex-nowrap rounded-2xl object-cover"
            />
          </div>

          <div className="flex flex-col">
            <div className="flex w-full justify-between">
              <span
                className={`line-clamp-1 h-[2.875rem] ${
                  offer?.variation && 'w-[30rem]'
                } text-[1.875rem] font-medium leading-[2.875rem]`}
              >
                {product.title}
              </span>
            </div>

            <span className="text-[1.625rem] font-semibold text-[#7F7F7F]">
              {formatCurrency(product.price)}
            </span>
          </div>
        </div>

        <button type="button" aria-label="View Product">
          <ArrowUpRightIcon className="h-[3rem] w-[3rem] text-[#444444]" />
        </button>
      </Link>

      {product.out_of_stock && (
        <div className="flex h-[5rem] w-full items-center justify-center bg-brand-secondary">
          <span className="text-[1.8rem] font-bold text-white">SOLD</span>
        </div>
      )}
    </div>
  );
}
