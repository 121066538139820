import { useMutation, useQuery } from '@tanstack/react-query';
import { db, FirebaseCallable, functions, httpsCallable } from '@util/firebase';
import { getPublicUserDoc, getUserToken, userRef } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { UserDocument } from 'models/user';
import { useCallback } from 'react';

const colRef = collection(db, 'users');
export const useUser = (uid?: string) => {
  const { showErrorToast } = useToastContext();
  const { signInWithToken } = useAuth();
  const getUserById = useCallback(async (uid: string) => {
    const docRef = doc(colRef, uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as UserDocument;
  }, []);

  const getUserByEmail = useCallback(async (email: string) => {
    const q = query(userRef, where('email', '==', email), limit(1));
    const docSnap = await getDocs(q);
    const user = docSnap.size ? docSnap.docs[0].data() : null;
    return user;
  }, []);

  const getUserByPhone = useCallback(async (phone: string) => {
    const { data } = await httpsCallable<{ phone: string }, UserDocument>(
      functions,
      FirebaseCallable.getUserByPhone
    )({ phone });
    return data;
  }, []);

  const getUserByAccountId = useCallback(async (account_id: string) => {
    const q = query(userRef, where('account_id', '==', account_id), limit(1));
    const docSnap = await getDocs(q);
    const user = docSnap.size ? docSnap.docs[0].data() : null;
    return user;
  }, []);

  const impersonateUserMutation = useMutation({
    mutationFn: getUserToken,
    onSuccess: async (token) => {
      if (!token) return;
      await signInWithToken({ email: token, password: '' });
      window.location.href = '/';
    },
    onError: (error) => {
      showErrorToast((error as Error).message);
    },
  });

  // Use this if using in a component
  const data = useQuery({
    queryKey: ['user', uid],
    queryFn: () => getUserById(uid!),
    enabled: !!uid,
  });

  const publicUserData = useQuery({
    queryKey: ['publicUser', uid],
    queryFn: () => getPublicUserDoc({ uid }),
    enabled: !!uid,
  });
  return {
    data,
    publicUserData,
    getUserById,
    getUserByEmail,
    getUserByPhone,
    getUserByAccountId,
    impersonateUserMutation,
  };
};
