// copying this here so I don't have to import the entire heic-to module just for the type
interface HeicToOptions {
  /**
   * The HEIC image blob to convert.
   */
  blob: Blob;

  /**
   * The desired output image MIME type (e.g., 'image/jpeg', 'image/png').
   */
  type: string;

  /**
   * The quality of the output image, between 0 and 1.
   */
  quality?: number;
}
export async function convertHeic(
  heicTo: (options: HeicToOptions) => Promise<Blob>,
  file: File,
  type: string = 'image/jpeg'
) {
  if (!heicTo) throw new Error('HEIC module not loaded yet');
  //  convert to base64
  const localUrl = URL.createObjectURL(file);
  const res = await fetch(localUrl);
  const blob = await res.blob();
  const converted = await heicTo({ blob, type });
  // replace case insensitive extension
  const newName = file.name.replace(/.heic|.heif/i, `.${type.split('/')[1]}`);
  return new File([converted as Blob], newName, {
    type,
  });
}

export function anyAreHeic(files: File[]) {
  const anyHeic = files.some((file) => file.type === 'image/heic');
  return anyHeic;
}
