import { ChevronDownIcon } from '@heroicons/react/20/solid';

import Image from 'next/image';
export const CreditCard = ({
  label,
  selected,
  onClick,
  noBorder,
  name,
  showArrow,
  onClickArrow,
  arrowDirection = 'down',
}: {
  label: string;
  selected: boolean;
  onClick?: () => void;
  name: string;
  noBorder?: boolean;
  showArrow?: boolean;
  onClickArrow?: () => void;
  arrowDirection?: 'up' | 'down';
}) => {
  const handleClick = () => {
    if (!onClick && onClickArrow) {
      onClickArrow();
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`flex w-full flex-row items-center justify-between gap-[3rem] rounded-[2rem] px-8 py-6 text-start transition-colors sm:flex-col sm:items-start sm:rounded-2xl sm:p-[2.4rem] ${
        selected
          ? !noBorder && 'border-[1px] border-brand-secondary bg-inherit'
          : 'bg-[#F4f4f4]'
      }`}
      onClick={handleClick}
    >
      <div className="flex w-full items-center justify-between gap-x-8">
        <div className="flex items-center gap-x-8">
          {label.toLowerCase().includes('visa') && (
            <Image
              src={'/cc_brands/visa.png'}
              alt={'Visa'}
              className="h-16 w-24 object-contain"
              height={24}
              width={24}
            />
          )}

          {label.toLowerCase().includes('amex') && (
            <Image
              src={'/cc_brands/amex.png'}
              alt={'Amex'}
              className="h-16 w-24 object-contain"
              height={24}
              width={24}
            />
          )}

          {label.toLowerCase().includes('mastercard') && (
            <Image
              src={'/cc_brands/mastercard.png'}
              alt={'mastercard'}
              className="h-16 w-24 object-contain"
              height={24}
              width={24}
            />
          )}

          <div className="flex flex-col">
            <h3 className="text-[1.8rem] font-semibold">{name}</h3>

            <span className="flex items-center text-[1.8rem] font-medium leading-[2.7rem] text-zinc-500">
              {label}
            </span>
          </div>
        </div>

        {showArrow && (
          <ChevronDownIcon
            aria-label="arrow down"
            onClick={(e) => {
              e.stopPropagation();
              onClickArrow?.();
            }}
            className="h-10 w-10 text-zinc-600"
            style={{
              transform: arrowDirection === 'up' ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s ease',
            }}
          />
        )}
      </div>
    </button>
  );
};
