import SafeImage from '@ui/SafeImage';
import { formatCurrency, getTimeRemainingParts } from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';
import OfferOperationModal from './OfferOperationModal';
import { useOffers } from './useOffers';
import { useState } from 'react';

interface MessageOfferProps {
  offer_id?: string;
  isSidebar?: boolean;
  isSender: boolean;
}

export function formatTimeRemaining(offer: { created: number }): string {
  if (!offer) return '';
  const time_remaining = offer.created + 172_800_000;
  const timeRemainingInSeconds = (time_remaining - Date.now()) / 1000;
  const { days, hours } = getTimeRemainingParts(timeRemainingInSeconds);
  return `${days}d ${hours}h`;
}

export default function MessageOffer({
  offer_id,
  isSidebar,
  isSender,
}: MessageOfferProps) {
  const { userDoc } = useAuth();
  const [openModal, setOpenModal] = useState<
    'accept' | 'decline' | 'counter' | ''
  >('');

  const { offer, product } = useOffers(offer_id);

  if (!product || !offer) return null;

  const sizes = [offer?.variation?.letter, offer?.variation?.number]
    .filter(Boolean)
    .join('/');

  return (
    <>
      <div className="flex h-full w-full flex-col overflow-y-auto border-l-[1px] border-[#d9d9d9] p-0 pb-4 @4xl:p-[2.4rem]">
        <Link
          href={getProductSlug(product)}
          target="_blank"
          className="flex w-full flex-col @4xl:w-[48rem]"
        >
          <div
            className={`${
              isSidebar
                ? 'flex flex-row items-start gap-x-4'
                : 'flex flex-row items-start bg-white sm:flex-col sm:items-center'
            }`}
          >
            <div className="relative ml-8 mt-8 min-h-[7rem] min-w-[7rem] @4xl:hidden @4xl:h-[14.08rem]">
              <SafeImage
                width={144}
                height={144}
                src={product.thumbnail}
                alt={'product image'}
                className="aspect-square h-[7rem] w-[7rem] rounded-[1rem] object-cover"
                skipOptimize
              />
            </div>

            <div className="relative hidden min-h-[10rem] min-w-[10rem] @4xl:block @4xl:h-[50rem] @4xl:w-full">
              <SafeImage
                width={100}
                height={100}
                src={product.thumbnail}
                alt={'product image'}
                skipOptimize
                fill
              />
            </div>

            <div className="flex w-full flex-col gap-[0.8rem] bg-white px-4 py-[0.8rem] @4xl:hidden sm:gap-0">
              <div
                className={`mt-4 flex w-full grow gap-[0.4rem] @4xl:flex-col`}
              >
                <div
                  className={`flex flex-col gap-x-0 ${
                    isSidebar ? 'sm:flex-col sm:gap-y-2' : 'sm:flex-row'
                  } sm:gap-x-4 sm:gap-y-0`}
                >
                  <div className="flex w-full items-center justify-between gap-x-8">
                    <span className="line-clamp-2 max-w-[50rem] break-words text-[1.8rem] font-semibold capitalize">
                      {product.title}
                    </span>

                    {sizes && (
                      <div className="whitespace-nowrap rounded-[1rem] bg-brand-lightest-gray px-4">
                        <span className="text-[2rem] font-semibold">
                          Size: {sizes}
                        </span>
                      </div>
                    )}
                  </div>

                  <span className="line-clamp-2 flex break-words text-[1.6rem] font-bold text-zinc-500 sm:font-semibold">
                    {formatCurrency(
                      product.on_sale ? product.price : product.og_price
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[1.6rem] hidden w-full flex-col gap-[0.4rem] @4xl:flex">
            <div className="mt-[1.6rem] flex w-full flex-row justify-between gap-[0.4rem]">
              <span className="text-[2.2rem] font-semibold">
                {product.title}
              </span>

              {sizes && (
                <div className="whitespace-nowrap rounded-[1rem] bg-brand-lightest-gray px-4">
                  <span className="text-[2rem] font-semibold">
                    Size: {sizes}
                  </span>
                </div>
              )}
            </div>
          </div>

          {!isSidebar && (
            <div className="mt-8 p-4">
              <div className="rounded-2xl bg-brand-lightest-gray p-6">
                <h3 className="text-[1.8rem] font-semibold text-zinc-700">
                  Offer Details
                </h3>

                <div className="mt-4">
                  <h3 className="text-[1.8rem] font-medium text-zinc-500 line-through">
                    {formatCurrency(
                      product.on_sale ? product.price : product.og_price
                    )}
                  </h3>

                  <h3 className="text-[2.4rem] font-bold text-black">
                    {formatCurrency(offer.price)}
                  </h3>
                </div>

                {offer.state === 0 && (
                  <div className="mt-4 flex items-center gap-x-2">
                    <span className={'text-[1.5rem] font-medium text-zinc-700'}>
                      Expires in {formatTimeRemaining(offer)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </Link>

        {!isSidebar && !isSender && (
          <div className="flex flex-row-reverse gap-[1.2rem]  px-4 @4xl:w-full sm:flex-col">
            {offer.state === 0 && offer.seller_id === userDoc?.uid && (
              <>
                <button
                  type="button"
                  className="flex items-center justify-center rounded-[1.5rem] bg-[#1E7A1E] p-6 text-[2rem] font-semibold text-white"
                  onClick={() => setOpenModal('accept')}
                >
                  Accept Offer
                </button>

                {!offer.is_counter && (
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-[1.5rem] border border-brand-black bg-[inherit] p-6 text-[2rem] font-semibold text-brand-black"
                    onClick={() => setOpenModal('counter')}
                  >
                    Counter Offer
                  </button>
                )}

                <button
                  type="button"
                  className="flex items-center justify-center rounded-[1.5rem] border border-brand-secondary bg-[inherit] p-6 text-[2rem] font-semibold text-brand-secondary"
                  onClick={() => setOpenModal('decline')}
                >
                  Decline Offer
                </button>
              </>
            )}
          </div>
        )}
      </div>

      <OfferOperationModal
        isOpen={openModal === 'decline'}
        setIsOpen={(isOpen) => setOpenModal(isOpen ? 'decline' : '')}
        type="decline"
        onClose={() => setOpenModal('')}
        offer={offer}
      />

      <OfferOperationModal
        isOpen={openModal === 'accept'}
        setIsOpen={(isOpen) => setOpenModal(isOpen ? 'accept' : '')}
        type="accept"
        onClose={() => setOpenModal('')}
        offer={offer}
      />

      <OfferOperationModal
        isOpen={openModal === 'counter'}
        setIsOpen={(isOpen) => setOpenModal(isOpen ? 'counter' : '')}
        type="counter"
        onClose={() => setOpenModal('')}
        offer={offer}
      />
    </>
  );
}

export const Hourglass = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.55016 14.5H11.4495C12.0683 14.5 12.5605 13.9909 12.4939 13.4044C12.0642 9.625 9.49985 9.6875 9.49985 8C9.49985 6.3125 12.097 6.40625 12.4936 2.59562C12.5561 2.00875 12.0683 1.5 11.4495 1.5H4.55016C3.93141 1.5 3.44485 2.00875 3.5061 2.59562C3.90266 6.40625 6.49985 6.28125 6.49985 8C6.49985 9.71875 3.93547 9.625 3.5061 13.4044C3.43922 13.9909 3.93141 14.5 4.55016 14.5Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7276 13.5H5.28482C4.79732 13.5 4.65982 12.9375 5.0017 12.5887C5.8292 11.75 7.49951 11.1494 7.49951 10.1875V7C7.49951 6.37969 6.31201 5.90625 5.57732 4.9C5.45607 4.73406 5.46826 4.5 5.77638 4.5H10.2367C10.4995 4.5 10.5564 4.73219 10.4367 4.89844C9.71263 5.90625 8.49951 6.37656 8.49951 7V10.1875C8.49951 11.1416 10.2404 11.6562 11.012 12.5897C11.3229 12.9659 11.2142 13.5 10.7276 13.5Z"
        fill="#444444"
      />
    </svg>
  );
};
