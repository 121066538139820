import BaseDrawer from '@c/drawers/BaseDrawer';
import CloseButton from '@c/icons/buttons/CloseButton';
import { ChatDocument } from '@models/chat';
import { markAsRead } from '@util/firestore/messages';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import Link from 'next/link';
import ConversationList from './dashboard/ConversationList';
import { OrderPreview } from './dashboard/MessageCenter';
import MessageOffer from './dashboard/MessageOffer';
import MessageWindow from './dashboard/MessageWindow';
import ProductPreview from './dashboard/messages/previews/ProductPreview';
import { useRouter } from 'next/navigation';

interface ChatProps {
  chat?: ChatDocument;
  setSelectedChat: (chat?: ChatDocument) => void;
}

const Chat = ({ chat, setSelectedChat }: ChatProps) => {
  const { user } = useAuth();
  const { chatOpen, setChatOpen, chats, offerChats } = useChat();
  let latestOfferIndex = 0;
  const messages = chat?.messages ?? [];
  for (let i = messages.length - 1; i >= 0; i--) {
    if (
      messages[i].offer_amount ||
      messages[i].content.startsWith('New Offer: ')
    ) {
      latestOfferIndex = i;
      break;
    }
  }

  return (
    <BaseDrawer
      show={chatOpen}
      position="right"
      dismiss={() => setChatOpen(false)}
    >
      <div className="flex h-full w-full flex-col">
        {!chat ? (
          <>
            <div className="flex shrink justify-between px-[1.6rem] pt-[2.4rem] xl:px-[2.4rem]">
              <h5 className="font-semibold">All Messages</h5>
              <CloseButton onClick={() => setChatOpen((prev) => !prev)} />
            </div>

            <div className="h-full grow overflow-y-auto">
              <ConversationList
                conversations={[...(chats ?? []), ...(offerChats ?? [])]}
                onConversationSelected={(chat) => {
                  setSelectedChat(chat);
                  if (
                    chat.id &&
                    user?.uid &&
                    chat.unread?.[user.uid] &&
                    chatOpen
                  )
                    markAsRead(chat.id, user.uid);
                }}
                selectedChat={chat}
                variant="messages"
              />
            </div>
            <div className="flex w-full shrink justify-center border-t-2  py-[1.6rem]">
              <Link
                href="/dashboard/messages"
                className="w-full text-center font-medium text-brand-secondary"
                onClick={() => {
                  setChatOpen(false);
                }}
              >
                View all messages
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="h-full grow overflow-y-auto">
              <MessageWindow
                chat={chat}
                key={chat.id}
                previewSlot={
                  chat.offer_id ? (
                    <MessageOffer
                      offer_id={chat.offer_id}
                      isSidebar
                      isSender={
                        chat.messages[latestOfferIndex]?.uid === user?.uid
                      }
                    />
                  ) : chat.product_id ? (
                    <ProductPreview productId={chat.product_id} />
                  ) : chat.order_id ? (
                    <OrderPreview
                      orderId={chat.order_id}
                      uids={chat.uids}
                    ></OrderPreview>
                  ) : null
                }
                goBack={() => {
                  setSelectedChat(undefined);
                }}
              />
            </div>
          </>
        )}
      </div>
    </BaseDrawer>
  );
};

export default Chat;
