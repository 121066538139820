import MessageWindow from '@c/dashboard/MessageWindow';
import BaseModal from './BaseModal';
import { MessageWindowProps } from '@c/dashboard/MessageWindow';
interface MobileChatModalProps {
  isOpen: boolean;
  goBack: () => void;
}
// import messagewindowprops and add that to mobile chat modal props

function MobileChatModal({
  isOpen,
  goBack,
  chat,
  previewSlot,
}: MobileChatModalProps & MessageWindowProps) {
  return (
    <BaseModal
      isOpen={isOpen}
      hideCloseIcon
      isFullScreen
      dismiss={() => goBack()}
    >
      <MessageWindow goBack={goBack} chat={chat} previewSlot={previewSlot} />
    </BaseModal>
  );
}

export default MobileChatModal;
