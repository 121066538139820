import { useQuery } from '@tanstack/react-query';
import { getIntegrationRequestsCount } from '@util/firestore/integrations/integrations.service';
import { getPendingOfferCount } from '@util/firestore/offers';
import { getPendingOrders } from '@util/firestore/orders';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useExpertChat } from 'features/shopWithAnExpert/hooks/useExpertChat';

export const useNotifications = () => {
  const { userDoc } = useAuth();

  //  Messages
  const { getUnreadCount } = useChat();
  const unreadCount = getUnreadCount();

  const { getUnreadCount: getExpertUnreadCount } = useExpertChat();
  const expertUnreadCount = getExpertUnreadCount();

  // Orders
  const { data: pendingOrders } = useQuery({
    queryKey: ['pendingOrdersCount', userDoc?.uid],
    queryFn: () => getPendingOrders(userDoc?.uid ?? ''),
  });
  const pendingOrdersCount = userDoc?.uid
    ? pendingOrders?.reduce(
        (acc, order) =>
          order.sellers![userDoc.uid].some(
            (i) => i.state === 0 || i.state === 7
          )
            ? acc + 1
            : acc,
        0
      )
    : 0;

  // Offers
  const { data: pendingOffersCount } = useQuery({
    queryKey: ['pendingOffersCount', userDoc?.uid],
    queryFn: () => getPendingOfferCount(userDoc?.uid),
  });

  // Integation Requests
  const { data: integrationRequestsCount = 0 } = useQuery({
    queryKey: ['integrationRequests'],
    queryFn: () => getIntegrationRequestsCount(),
  });

  // Stripe Details
  const requiresStripeDetails = userDoc?.stripe_requirements?.length;

  // Total
  const total =
    unreadCount +
    expertUnreadCount +
    (pendingOrdersCount ?? 0) +
    (pendingOffersCount ?? 0) +
    (requiresStripeDetails ? 1 : 0);

  return {
    unreadCount,
    expertUnreadCount,
    pendingOrdersCount,
    pendingOffersCount,
    pendingReviewsCount: 0, // possibly revisit
    total,
    requiresStripeDetails,
    integrationRequestsCount,
  };
};
