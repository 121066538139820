import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useToastContext } from 'context/ToastContext';

export const useSupportBot = ({
  onNextMessage,
}: {
  onNextMessage: (message: string) => void;
}) => {
  const { showSuccessToast, showErrorToast } = useToastContext();
  const [state, setState] = useState<'ready' | 'feedback'>('ready');
  const [lastRequestId, setLastRequestId] = useState<string | null>(null);
  const getURL = (endpoint: 'getNextMessage' | 'submitFeedback') => {
    if (process.env.NODE_ENV === 'development') {
      return `http://127.0.0.1:5001/mxlocker-b1d4a/us-central1/support-${endpoint}`;
    } else if (process.env.NEXT_PUBLIC_IS_STAGING) {
      return `https://us-central1-mx-locker-staging.cloudfunctions.net/support-${endpoint}`;
    }
    return `https://us-central1-mxlocker-b1d4a.cloudfunctions.net/support-${endpoint}`;
  };
  const getNextMessageMutation = useMutation({
    mutationFn: async (chat_id: string) => {
      const response = await fetch(getURL('getNextMessage'), {
        method: 'POST',
        body: JSON.stringify({ chat_id }),
      });
      const json = (await response.json()) as {
        message: string;
        request_id: string;
      };
      return json;
    },
    onSuccess: (data) => {
      setState('feedback');
      onNextMessage(data.message);
      setLastRequestId(data.request_id);
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const submitFeedbackMutation = useMutation({
    mutationFn: async ({
      feedback,
    }: {
      feedback: 'helpful' | 'not helpful' | '';
    }) => {
      if (feedback === '') return;
      const response = await fetch(getURL('submitFeedback'), {
        method: 'POST',
        body: JSON.stringify({ feedback, request_id: lastRequestId }),
      });
      showSuccessToast('Feedback submitted');
      return response.text();
    },
    onSuccess: () => {
      setState('ready');
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });
  return { getNextMessageMutation, submitFeedbackMutation, state };
};
